import * as classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../utils/data-hooks'
import * as sc from '../../classes.scss'
import * as s from './title.scss'
import {TitleProps} from '.'

export const Title = ({event, onImage, isMobile}: TitleProps) => (
  <div
    className={classNames(s.root, sc.textNoMargin, sc.preWrap, {
      [s.onImage]: onImage,
      [s.mobile]: isMobile,
    })}
    data-hook={DH.eventTitle}
  >
    {event.title}
  </div>
)
