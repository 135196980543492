import {getFormattedStartTime} from '@wix/wix-events-commons-statics'
import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../../../../commons/selectors/environment'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {DayEvents as DayEventsPresentation} from './day-events'

export interface EventInfoOwnProps {
  events: wix.events.Event[]
}

export interface EventInfoRuntimeProps {
  firstEventTime: string
}

export interface EventInfoProps extends EventInfoRuntimeProps, EventInfoOwnProps, WithNamespaces {}

const mapRuntime = (context: AppProps, ownProps: EventInfoOwnProps): EventInfoRuntimeProps => ({
  firstEventTime: ownProps.events[0] && getFormattedStartTime(ownProps.events[0], getFullLocale(context.state)),
})

export const DayEvents = connect<EventInfoOwnProps, EventInfoRuntimeProps>(mapRuntime)(
  withNamespaces()(DayEventsPresentation),
)
