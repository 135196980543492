import * as React from 'react'
import {AnimatorProps, Animator} from 'wix-animations/dist/src'

interface AnimatedProps {
  disabled: boolean
  show: boolean
}

export const Animated: React.FunctionComponent<AnimatedProps & AnimatorProps> = ({
  disabled,
  show,
  children,
  ...rest
}) => {
  if (disabled) {
    return show ? <>{children}</> : null
  }
  return (
    <Animator show={show} {...rest}>
      {children}
    </Animator>
  )
}
