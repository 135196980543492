/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface TwitterNewProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
const TwitterNew: React.SFC<TwitterNewProps> = ({size, ...props}) => (
  <svg viewBox="0 0 24 24" fill="currentColor" width={ size || "24" } height={ size || "24" } {...props}>
    <defs>
      <path d="M24,2.33715077 C23.1055847,2.7407215 22.1562501,3.00532017 21.184,3.12202667 C22.2082976,2.49698751 22.9753634,1.51798579 23.344,0.365231135 C22.3792025,0.945212208 21.3241889,1.35483086 20.224,1.57659953 C18.8399204,0.0833397089 16.6970098,-0.40289857 14.816848,0.34968679 C12.9366862,1.10227215 11.6998617,2.94134037 11.696,4.99016106 C11.6979289,5.37212948 11.740848,5.75275195 11.824,6.12531216 C7.86590517,5.92378278 4.1784882,4.02801698 1.68,0.910103664 C1.23682584,1.6695842 1.00462382,2.53643164 1.008,3.4187876 C0.867369834,5.02635733 1.54988427,6.59472607 2.816,7.57344064 C1.9301656,7.44388077 1.05741341,7.23495129 0.208,6.94910753 L0.208,7.01235167 C0.377478697,9.45708774 2.16383534,11.4727132 4.544,11.9048529 C4.187335,12.0215325 3.81478348,12.0806338 3.44,12.0799905 C3.16029583,12.0809323 2.88132084,12.0510262 2.608,11.9908001 C3.26871598,14.0295595 5.13101599,15.4204464 7.248,15.4562542 C5.51277319,16.8463876 3.36382934,17.5992602 1.152,17.5919599 C0.766953322,17.5926519 0.382228459,17.5693644 0,17.5222292 C4.31219206,20.3214607 9.78762388,20.5134058 14.2803365,18.0228365 C18.7730491,15.5322672 21.5673184,10.7559505 21.568,5.56584483 C21.568,5.35016612 21.552,5.13448741 21.552,4.91718706 C22.5133746,4.2121312 23.3424518,3.33833661 24,2.33715077 L24,2.33715077 Z"
        id="path-twitter-new" />
    </defs>
    <g id="09-Icons-/-Social-/-Twitter" stroke="none" fill="none" strokeWidth="1" fillRule="evenodd">
      <g id="&#x1F3A8;-Color" transform="translate(0 2)">
        <mask id="mask-2" fill="currentColor">
          <use xlinkHref="#path-twitter-new" />
        </mask>
        <use id="Mask" fill="currentColor" fillRule="nonzero" xlinkHref="#path-twitter-new" />
      </g>
    </g>
  </svg>
);
TwitterNew.displayName = 'TwitterNew';
export default TwitterNew;
/* tslint:enable */
/* eslint-enable */
