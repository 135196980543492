import * as React from 'react'
import * as s from './description.scss'

interface DescriptionProps {
  event: wix.events.Event
  visible: boolean
}

export const Description = ({event, visible}: DescriptionProps) => {
  if (!visible || !event.description) {
    return null
  }

  return (
    <div className={s.description} data-hook="ev-list-item-description">
      {event.description}
    </div>
  )
}
