import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {EventImage as EventImagePresentation} from './event-image'

export interface EventImageOwnProps {
  event: wix.events.Event
  imagePosition: number
  imageOpacity: number
  width: number
  height: number
}

export interface EventImageRuntimeProps {}

export interface EventImageProps extends EventImageRuntimeProps, EventImageOwnProps {}

const mapRuntime = (context: AppProps): EventImageRuntimeProps => ({})

export const EventImage = connect<EventImageOwnProps, EventImageRuntimeProps>(mapRuntime)(EventImagePresentation)
