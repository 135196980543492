import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary'
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles'
import {ErrorPage} from '../../../commons/components/error-page'
import {getWidgetSentryConfig} from '../../utils/sentry'
import {App} from './app'

export default {
  component: withSentryErrorBoundary(
    withStyles(App, {cssPath: ['eventsWidget.min.css', 'eventsWidget.stylable.bundle.css']}),
    getWidgetSentryConfig('events-widget-viewer'),
    ErrorPage,
  ),
}
