export const getElementWidth = (selector: string, parent?: Element) => {
  try {
    const parentEl = parent || document
    return parentEl.querySelector(selector) ? parentEl.querySelector(selector).getBoundingClientRect().width : 0
  } catch (e) {
    return 0
  }
}

export const getElementHeight = (selector: string, parent?: Element) => {
  try {
    const parentEl = parent || document
    return parentEl.querySelector(selector) ? parentEl.querySelector(selector).getBoundingClientRect().height : 0
  } catch (e) {
    return 0
  }
}

export const getElementStyle = (element: Element, prop: string) => {
  const style = window.getComputedStyle(element)

  return style && style[prop]
}

export const setElementStyle = (element: any, prop: string, value: string) =>
  !!element && !!value && (element.style[prop] = value)

export const scrollTo = (selector: string) => {
  const element = document.querySelector(selector)
  if (element) {
    element.scrollIntoView()
  }
}
