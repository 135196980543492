import * as i18next from 'i18next'
import * as memoize from 'lodash.memoize'

export const i18nInstance = i18next.createInstance()

const importResource = (resource, language, staticsBaseUrl) =>
  fetch(`${staticsBaseUrl}assets/locale/${resource}_${language}.json`).then(res => res.json())

export const importResources = async (resources: string[], language: string, staticsBaseUrl: string) =>
  (await Promise.all(resources.map(resource => importResource(resource, language, staticsBaseUrl)))).reduce(
    (ret: object, translation: object) => ({...ret, ...translation}),
    {},
  )

export const ssrI18N = (translation: Dictionary<string>) => {
  return i18next.init({
    resources: {en: {translation}},
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lowerCaseLng: true,
    keySeparator: false,
    react: {
      wait: false,
    },
  })
}

type MemoizedI18n = (locale: string, resources: string[], staticsBaseUrl: string) => i18next.i18n

export const memoizedI18n: MemoizedI18n = memoize(function i18n(locale: string, resources: string[], staticsBaseUrl) {
  return i18nInstance
    .use({
      type: 'backend',
      read: async (language, namespace, callback) => {
        return importResources(resources, language, staticsBaseUrl)
          .then(translation => callback(null, translation))
          .catch(error => callback(error))
      },
    })
    .init({
      lng: locale,
      fallbackLng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        wait: true,
      },
    })
})

export default memoizedI18n
