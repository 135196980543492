import {getCalendarMonth, WEEKDAY} from '@wix/wix-events-commons-statics'
import {TranslationFunction} from 'i18next'
import {Moment} from 'moment-timezone'
import * as moment from 'moment-timezone'
import {MonthlyCalendarCellSizes} from '..'
import {isEditor} from '../../../../../../commons/selectors/environment'
import {getCalendarWeekStartDay} from '../../../../../../commons/selectors/settings'
import {closeMonthlyCalendarPopup} from '../../../../../actions/calendar-layout'
import {getEventDetailsStyleHash, getReferenceDate} from '../../../../../selectors/calendar-layout'
import {getComponentConfig} from '../../../../../selectors/settings'
import {getBorderWidth} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MonthlyCalendarPopup as MonthlyCalendarPopupPresentation} from './monthly-calendar-popup'

export interface MonthlyCalendarPopupOwnProps {
  getCellSizes: () => MonthlyCalendarCellSizes
  t: TranslationFunction
}

export interface MonthlyCalendarPopupRuntimeProps {
  open: boolean
  week: number
  weekDayIndex: number
  selectedDate: Moment
  selectedEventId: string
  borderWidth: number
  popupStyleHash: string
  isEditor: boolean
  rtl: boolean
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
}

export interface MonthlyCalendarPopupProps extends MonthlyCalendarPopupRuntimeProps, MonthlyCalendarPopupOwnProps {}

export interface MonthlyCalendarState {
  contentHeight: number
}

const mapRuntime = (context: AppProps): MonthlyCalendarPopupRuntimeProps => {
  const {
    monthly: {selectedDate},
  } = context.state.calendarLayout

  const referenceDate = getReferenceDate(context.state)

  const gridPosition = getDateGridPosition(
    moment(Number(selectedDate)),
    referenceDate,
    getCalendarWeekStartDay(getComponentConfig(context.state)),
  )

  const insideEditor = isEditor(context.state)

  return {
    open: Boolean(selectedDate),
    ...gridPosition,
    selectedDate: moment(Number(selectedDate)),
    selectedEventId: context.state.calendarLayout.monthly.selectedEventId,
    borderWidth: getBorderWidth(context),
    popupStyleHash: insideEditor && getEventDetailsStyleHash(context),
    isEditor: insideEditor,
    rtl: context.isRTL,
    closeMonthlyCalendarPopup: context.actions.closeMonthlyCalendarPopup,
  }
}

export const MonthlyCalendarPopup = connect<MonthlyCalendarPopupOwnProps, MonthlyCalendarPopupRuntimeProps>(mapRuntime)(
  MonthlyCalendarPopupPresentation,
)

const getDateGridPosition = (date: Moment, referenceDate: Moment, weekStartDay: WEEKDAY) => {
  const calendarMonth = getCalendarMonth(referenceDate, weekStartDay)

  let weekDayIndex: number

  const week = calendarMonth.findIndex(weekDays =>
    Boolean((weekDayIndex = weekDays.findIndex(day => day.date.isSame(date, 'day'))) !== -1),
  )

  return {week, weekDayIndex}
}
