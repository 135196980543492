/* eslint-disable */
/* tslint:disable */
import * as React from 'react';
export interface LinkedinProps extends React.SVGAttributes<SVGElement> {
size?: string;
}
const Linkedin: React.SFC<LinkedinProps> = ({size, ...props}) => (
  <svg viewBox="0 0 19 16" fill="currentColor" width={ size || "19" } height={ size || "16" } {...props}>
    <path d="M2.30367607,0.025974026 C3.49850532,0.025974026 4.46947897,0.885212121 4.46947897,1.94130736 C4.46947897,2.99987879 3.49850532,3.85911688 2.30367607,3.85911688 C1.10464953,3.85911688 0.13647407,2.99987879 0.13647407,1.94130736 C0.13647407,0.885212121 1.10464953,0.025974026 2.30367607,0.025974026 L2.30367607,0.025974026 Z M0.433082736,15.9491169 L4.17287031,15.9491169 L4.17287031,5.31387879 L0.433082736,5.31387879 L0.433082736,15.9491169 Z M6.51467968,5.31325974 L10.0963692,5.31325974 L10.0963692,6.76554545 L10.1467367,6.76554545 C10.6448154,5.92983117 11.8634294,5.04830736 13.680857,5.04830736 C17.4612184,5.04830736 18.159368,7.25087879 18.159368,10.1158312 L18.159368,15.9484978 L14.427975,15.9484978 L14.427975,10.776974 C14.427975,9.54259307 14.4013922,7.95659307 12.4874268,7.95659307 C10.5426813,7.95659307 10.2460727,9.29992641 10.2460727,10.6865931 L10.2460727,15.9484978 L6.51467968,15.9484978 L6.51467968,5.31325974 Z"
      fill="currentColor" fillRule="evenodd" />
  </svg>
);
Linkedin.displayName = 'Linkedin';
export default Linkedin;
/* tslint:enable */
/* eslint-enable */
