import * as classNames from 'classnames'
import * as React from 'react'
import {LIST_LOCATION_AND_DATE_FORMAT} from '../../../../commons/constants/settings'
import {getListAlignmentClassName, getListImageLayoutClassName} from '../../../selectors/classes'
import {Image} from '../../commons/image'
import {FullDateLocation} from '../../full-date-location'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import * as s from './side-by-side-item.scss'
import {SideBySideItemProps} from '.'

export class SideBySideItem extends React.Component<SideBySideItemProps> {
  getContainerClasses() {
    const {
      event,
      evenItem,
      showFullDate,
      showLocation,
      showDescription,
      showSocialBar,
      imageLayout,
      alignment,
      locationAndDateFormat,
    } = this.props

    return classNames(
      s.container,
      s[`${getListImageLayoutClassName(imageLayout)}Image`],
      s[getListAlignmentClassName(alignment)],
      {
        [s.even]: evenItem,
        [s.withFullDateLocation]: locationAndDateFormat === LIST_LOCATION_AND_DATE_FORMAT.FULL,
        [s.noFullDateLocation]: !showFullDate && !showLocation,
        [s.noDescription]: !showDescription || !event.description,
        [s.noSocialBar]: !showSocialBar,
      },
    )
  }

  render() {
    const {
      event,
      showImage,
      showDescription,
      showMembers,
      imageWidth,
      imageOpacity,
      imagePosition,
      membersEnabled,
      t,
      showRibbon,
    } = this.props

    return (
      <div className={this.getContainerClasses()}>
        {showImage ? (
          <div className={s.imageContainer} style={{width: `${imageWidth}%`}}>
            <div className={s.imageOverlay} data-hook="image-background" />
            <div className={s.image} data-hook="image">
              <Image image={event.mainImage} opacity={imageOpacity} gridPosition={imagePosition} />
            </div>
          </div>
        ) : null}
        <div className={s.content}>
          {showRibbon ? <Ribbon event={event} className={s.ribbon} /> : null}
          <div className={s.details}>
            {this.renderShortDateAndLocation()}
            <div className={s.title} data-hook="title">
              {event.title}
            </div>
            {this.renderFullDateAndLocation()}
            {showDescription && !!event.description && (
              <div className={s.description} data-hook="description">
                {event.description}
              </div>
            )}
            {!membersEnabled && (
              <div className={classNames(s.socialBar, s.cardContent)}>
                <SocialBar t={t} event={event} />
              </div>
            )}
          </div>
          {showMembers ? (
            <div className={s.members} data-hook="members">
              <Members event={event} />
            </div>
          ) : null}
          <div className={s.button}>
            <RsvpButton event={event} />
          </div>
          {membersEnabled && (
            <div className={classNames(s.socialBar, s.bellowContent, s.cardContent)}>
              <SocialBar t={t} event={event} />
            </div>
          )}
        </div>
      </div>
    )
  }

  renderShortDateAndLocation() {
    const {event, showDate, showVenue, alignment, locationAndDateFormat, fullLocale} = this.props

    return (
      locationAndDateFormat === LIST_LOCATION_AND_DATE_FORMAT.COMPACT &&
      (showDate || showVenue) && (
        <div className={classNames(s.shortDateLocation, s.cardContent)}>
          <ShortDateLocation
            event={event}
            alignment={alignment}
            showDate={showDate}
            showLocation={showVenue}
            fullLocale={fullLocale}
          />
        </div>
      )
    )
  }

  renderFullDateAndLocation() {
    const {event, showFullDate, showLocation, locationAndDateFormat, fullLocale} = this.props

    return (
      locationAndDateFormat === LIST_LOCATION_AND_DATE_FORMAT.FULL &&
      (showFullDate || showLocation) && (
        <div className={classNames(s.fullDateLocation, s.cardContent)}>
          <FullDateLocation event={event} showDate={showFullDate} showLocation={showLocation} fullLocale={fullLocale} />
        </div>
      )
    )
  }
}
