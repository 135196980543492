import {getDay, getFormattedFullDate, getMonth, getWeekDay} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {BrowserOnly} from '../../../../../commons/components/browser-only'
import {DATE_SIZE} from '../../../../../commons/constants/settings'
import * as sc from '../../../classes.scss'
import * as s from './date.scss'
import {DateProps} from '.'

const DATE_WIDTH = 108

export const Date = ({event, t, fullLocale, customColorClass = '', size}: DateProps) => {
  const {
    scheduling: {
      config: {startDate, timeZoneId, scheduleTbd},
    },
  } = event

  const monthDay = getDay(startDate, timeZoneId, fullLocale)
  const weekDay = getWeekDay(startDate, timeZoneId, fullLocale)
  const month = getMonth(startDate, timeZoneId, fullLocale)

  return (
    <BrowserOnly>
      <div
        className={classNames(s.listHeaderTitle, sc.row, customColorClass || s.color, [s[DATE_SIZE[size]]])}
        style={{minWidth: DATE_WIDTH}}
        aria-label={getFormattedFullDate(event, fullLocale)}
        data-hook={scheduleTbd ? 'ev-date-tbd' : 'ev-date'}
      >
        {scheduleTbd ? (
          <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-tbd-text">
            {t('listDateTbd')}
          </div>
        ) : (
          <>
            <div className={classNames(sc.textNoMargin, s.monthDay)} data-hook="ev-date-month-day" aria-hidden="true">
              {monthDay}
            </div>
            <div className={classNames(sc.textNoMargin, s.weekAndMonth)}>
              <div
                className={s.weekDay}
                style={customColorClass ? null : {opacity: 0.5}}
                data-hook="ev-date-weekday"
                aria-hidden="true"
              >
                {weekDay}
              </div>
              <div className={s.month} data-hook="ev-date-month" aria-hidden="true">
                {month}
              </div>
            </div>
          </>
        )}
      </div>
    </BrowserOnly>
  )
}
