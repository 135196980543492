import {WEEK_LENGTH} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {getPopupPlacement, CALENDAR_WEEKS} from '../../../../../utils/calendar-layout-popup'
import {MonthlyCalendarEventDetails} from './event-details'
import {MonthlyCalendarEventList} from './event-list'
import * as s from './monthly-calendar-popup.scss'
import {Triangle, TriangleDirection} from './triangle'
import {MonthlyCalendarPopupProps, MonthlyCalendarState} from '.'

export class MonthlyCalendarPopup extends React.PureComponent<MonthlyCalendarPopupProps, MonthlyCalendarState> {
  state: MonthlyCalendarState = {
    contentHeight: 0,
  }

  popupElement: HTMLElement

  componentDidUpdate(prevProps) {
    const {open, selectedDate, selectedEventId, popupStyleHash, isEditor} = this.props
    const {
      open: prevOpen,
      selectedDate: prevSelectedDate,
      selectedEventId: prevSelectedEventId,
      popupStyleHash: prevPopupStyleHash,
    } = prevProps

    if (!isEditor) {
      if (open && (!prevOpen || selectedEventId !== prevSelectedEventId)) {
        const firstFocusableElement: HTMLElement = this.popupElement.querySelector(
          "[data-hook^='calendar-popup-close-button']",
        )

        setTimeout(() => firstFocusableElement.focus())
      }
    }

    if (open) {
      if (!this.state.contentHeight) {
        this.setState({contentHeight: this.popupElement.clientHeight})
      } else if (!selectedDate.isSame(prevSelectedDate, 'day') || prevSelectedEventId !== selectedEventId) {
        // This prevents flickering while updating popup position
        this.setState({contentHeight: 0})
      } else if (popupStyleHash !== prevPopupStyleHash) {
        // This ensures that popup resizes when setting changes impact content size
        this.setState({contentHeight: 0})
      }
    }
  }

  handleKeyUp = event => {
    if (event.key === 'Escape') {
      this.props.closeMonthlyCalendarPopup()
    }
  }

  render() {
    const {open, selectedEventId, getCellSizes, borderWidth, week, weekDayIndex, isEditor, rtl, t} = this.props

    const ready =
      Boolean(this.state.contentHeight) &&
      Object.keys(getCellSizes()).length === CALENDAR_WEEKS &&
      Object.keys(getCellSizes()[CALENDAR_WEEKS - 1]).length === WEEK_LENGTH

    if (!open) {
      this.setState({contentHeight: 0})
      return null
    }

    const popupPlacement = ready
      ? getPopupPlacement(getCellSizes(), borderWidth, this.state.contentHeight, week, weekDayIndex, rtl)
      : null

    const style = ready
      ? Object.entries(popupPlacement.styles).reduce((styles, [rule, value]) => {
          styles[rule] = `${value}px`
          return styles
        }, {})
      : {}

    const triangle = popupPlacement ? popupPlacement.triangle : {direction: TriangleDirection.left, top: 0}

    return (
      <div
        ref={div => {
          this.popupElement = div
        }}
        style={style}
        className={classNames(s.popup, {[s.visible]: ready, [s.editor]: isEditor})}
        onKeyUp={this.handleKeyUp}
        data-hook={`calendar-popup`}
      >
        <div className={s.relativeContainer}>
          <div className={s.content}>
            {selectedEventId ? <MonthlyCalendarEventDetails /> : <MonthlyCalendarEventList t={t} />}
          </div>
          <div className={classNames(s.triangle, s[triangle.direction])} style={{top: `${triangle.top}px`}}>
            <Triangle direction={TriangleDirection[triangle.direction]} />
          </div>
        </div>
      </div>
    )
  }
}
