import {ImageService, hasImage} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {getImageStyle} from '../../../commons/utils/image'
import * as s from './event-image.scss'
import {EventImageProps} from '.'

export class EventImage extends React.PureComponent<EventImageProps> {
  fastCachedImageStyle: React.CSSProperties
  fastCachedImageId: string
  cachedImageStyle: React.CSSProperties
  cachedImageId: string

  imageAvailable = () => hasImage(this.props.event)

  getImageStyle = (fastLoad = false) => {
    const {event, imagePosition, imageOpacity} = this.props
    let {width, height} = this.props

    if (fastLoad) {
      width = Math.ceil(width / 50)
      height = Math.ceil(height / 50)
    }

    if (fastLoad) {
      if (event.mainImage.id === this.fastCachedImageId) {
        return this.cachedImageStyle
      }
    } else {
      if (event.mainImage.id === this.fastCachedImageId) {
        return this.fastCachedImageStyle
      }
    }

    const container = ImageService.getResizedImageSize(event.mainImage.width, event.mainImage.height, width, height)
    const imageStyle = getImageStyle({
      image: event.mainImage,
      container,
      opacity: imageOpacity,
      position: imagePosition,
    })

    this.cachedImageStyle = imageStyle
    this.cachedImageId = event.mainImage.id

    return imageStyle
  }

  render() {
    return this.imageAvailable() ? (
      <div className={s.imageContainer}>
        <div className={s.fastLoadImage} style={this.getImageStyle(true)} data-hook="fastLoadImage" />
        <div className={s.image} style={this.getImageStyle()} data-hook="image" />
      </div>
    ) : null
  }
}
