import * as classNames from 'classnames'
import * as React from 'react'
import {CSSProperties, KeyboardEvent} from 'react'
import {DH} from '../../../utils/data-hooks'
import {DesktopItem, MobileItem} from '../item'
import * as s from './item.scss'
import {ItemContainerProps} from '.'

export class ItemContainer extends React.Component<ItemContainerProps> {
  state = {hovered: false}

  render() {
    const {t, event, opened, mobile, listLayout} = this.props
    const {hovered} = this.state

    return (
      <div
        style={this.getContainerStyle()}
        onKeyPress={this.onKeyPress}
        tabIndex={0}
        data-hook={DH.listItem}
        className={classNames(s.container, mobile && !listLayout ? s.cardBorderColor : s.listDividerColor, {
          [s.listHoveredBorderColor]: listLayout && (hovered || opened),
        })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={this.toggle}
      >
        {mobile ? (
          <MobileItem t={t} event={event} hovered={hovered} />
        ) : (
          <DesktopItem t={t} event={event} hovered={hovered} />
        )}
      </div>
    )
  }

  onKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      this.toggle()
      event.stopPropagation()
    }
  }

  onMouseEnter = () => {
    this.setState({hovered: true})
  }

  onMouseLeave = () => {
    this.setState({hovered: false})
  }

  toggle = () => {
    const {event, opened, additionalComponentsHidden, open, close} = this.props

    if (opened) {
      close()
    } else if (!additionalComponentsHidden) {
      open(event.id)
    }
  }

  getContainerStyle = (): CSSProperties => {
    const {lastItem, listDividerWidth, listStripWidth, opened, mobile} = this.props
    const {hovered} = this.state

    const padding = `${listStripWidth + (mobile ? 0 : 10)}px 0`

    if (hovered || opened) {
      return {
        padding,
        marginTop: -listDividerWidth,
        borderBottomWidth: listDividerWidth,
        borderTopWidth: listDividerWidth,
      }
    }

    return {
      padding,
      borderBottomWidth: listDividerWidth,
      borderBottomColor: lastItem ? 'transparent' : undefined,
    }
  }
}
