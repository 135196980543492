import * as classNames from 'classnames'
import * as React from 'react'
import * as s from './triangle.scss'
import {TriangleDirection, TriangleProps} from '.'

export class Triangle extends React.PureComponent<TriangleProps> {
  render() {
    const {direction, borderWidth} = this.props

    // Pythagorean theorem is used to emulate line width using horizontal shifts of triangles
    const offset = Math.sqrt(Math.pow(borderWidth, 2) / 2)

    // Resulting offset value is split between inner and outer triangles
    // Triangles are moved to opposite directions to create a line
    const splitOffset = Math.ceil(offset / 2)

    // To connect to border, triangle position needs to be slightly shifted
    const offsetShift = Math.floor(borderWidth / 2)

    const innerLeft = `${-splitOffset - offsetShift}px`
    const outerLeft = `${splitOffset - offsetShift}px`

    return (
      <div
        className={classNames(s.triangle, {
          [s.left]: direction === TriangleDirection.left,
          [s.right]: direction === TriangleDirection.right,
        })}
      >
        <div className={s.outer} style={{left: outerLeft}} />
        <div className={s.inner} style={{left: innerLeft}} />
      </div>
    )
  }
}
