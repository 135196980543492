import {hasImage} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {IMAGE_RATIO} from '../../../../commons/constants/settings'
import {getImageStyle} from '../../../../commons/utils/image'
import {getListAlignmentClassName} from '../../../selectors/classes'
import {DH} from '../../../utils/data-hooks'
import {FullDateLocation} from '../../full-date-location'
import {Members} from '../../members'
import {Ribbon} from '../../ribbon'
import {RsvpButton} from '../../rsvp-button'
import {ShortDateLocation} from '../../short-date-location'
import {SocialBar} from '../../social-bar'
import {DynamicStyle} from '../../../../commons/components/dynamic-style'
import * as s from './card.scss'
import {CardProps} from '.'

const IMAGE_SIZE = {
  [IMAGE_RATIO['1:1']]: {
    width: 768,
    height: 768,
  },
  [IMAGE_RATIO['16:9']]: {
    width: 768,
    height: 432,
  },
}

export class Card extends React.Component<CardProps> {
  private card = React.createRef<HTMLDivElement>()
  private content = React.createRef<HTMLDivElement>()
  private bottom = React.createRef<HTMLDivElement>()
  private details = React.createRef<HTMLDivElement>()
  private topContainer = React.createRef<HTMLDivElement>()
  private contentMarginTop = 24
  private cardHeight = 0

  getImageStyle() {
    const {event, imageRatio, imageOpacity} = this.props
    const container = IMAGE_SIZE[imageRatio]

    if (hasImage(event)) {
      return getImageStyle({image: event.mainImage, container, opacity: imageOpacity})
    }

    return {}
  }

  getContentPadding = (): number => {
    const cardHeight = this.getCardHeight()
    const topHeight = this.topContainer.current?.clientHeight ?? 0
    const bottomHeight = this.bottom.current?.clientHeight ?? 0
    const detailsHeight = this.details.current?.clientHeight ?? 0
    const sum = this.contentMarginTop + detailsHeight + topHeight + bottomHeight
    return (cardHeight - sum) / 2
  }

  scrollToTop = () => {
    this.content.current.scrollTop = 0
  }

  getContainerClasses() {
    const {
      showImage,
      showFullDate,
      showLocation,
      showDescription,
      showSocialBar,
      showMembers,
      membersEnabled,
      hideAdditionalComponents,
      alignment,
      hasRibbon,
    } = this.props

    return classNames(s.container, s[getListAlignmentClassName(alignment)], {
      [s.noImage]: !showImage,
      [s.noDateLocation]: !showFullDate && !showLocation,
      [s.noDescription]: !showDescription,
      [s.noSocialBar]: !showSocialBar,
      [s.noAdditionalComponents]: hideAdditionalComponents,
      [s.noMembers]: !showMembers,
      [s.membersEnabled]: membersEnabled,
      [s.hasRibbon]: hasRibbon,
    })
  }

  getCardHeight = () => {
    const cardHeight = this.card.current?.clientHeight ?? 0
    if (!this.props.expanded && cardHeight) {
      const horizontalBorders = (this.props.borderWidth ?? 1) * 2
      this.cardHeight = cardHeight + horizontalBorders
    }

    return this.cardHeight
  }

  componentDidMount() {
    if (this.props.preview) {
      this.forceUpdate()
    }
  }

  render() {
    const {
      event,
      showDate,
      showVenue,
      showFullDate,
      showLocation,
      showMembers,
      alignment,
      fullLocale,
      t,
      showRibbon,
      imageRatio,
      editor,
      cardMargins,
      columns,
      compId,
      hideAdditionalComponents,
      maxWidth,
      minWidth,
    } = this.props
    const imageRatioClassName = imageRatio === IMAGE_RATIO['16:9'] ? s.image169 : s.image11
    const expanded = this.props.expanded && !hideAdditionalComponents
    const namespace = `${compId}-${event.id}-${s.card}`
    const cardHeight = this.getCardHeight()
    const contentPadding = this.getContentPadding()
    const detailsHeight = this.details.current?.clientHeight ?? 0

    return (
      <>
        <DynamicStyle namespace={namespace}>
          {`
            &.${s.card} {
              margin: ${cardMargins / 2}px;
              width: calc(${100 / columns}% - ${cardMargins}px);
              height: ${!editor && cardHeight ? `${cardHeight}px` : 'auto'};
              max-width: ${maxWidth}px;
              min-width: ${minWidth}px;
            }
            
            &.${s.hoverCard}:hover .${s.content},
            &.${s.hoverCard}.${s.expanded} .${s.content} {
              padding-top: ${contentPadding}px;
              overflow-y: ${contentPadding > 0 ? 'hidden' : 'scroll'};
            }
            
            &.${s.hoverCard}:hover,
            &.${s.hoverCard}.${s.expanded} {
              height: ${cardHeight}px;
            }

            &.${s.hoverCard}:hover .${s.content} > .${s.cardContentItem},
            &.${s.hoverCard}.${s.expanded} .${s.content} > .${s.cardContentItem} {
              width: ${this.topContainer.current?.clientWidth}px
            }

            &.${s.hoverCard}:hover .${s.detailsContainer},
            &.${s.hoverCard}.${s.expanded} .${s.detailsContainer} {
              height: ${detailsHeight}px;
            }
            
            .${s.content} {
              margin-top: ${this.contentMarginTop}px;
              overflow-y: ${editor ? 'visible' : 'hidden'};
            }
          `}
        </DynamicStyle>
        <div
          className={classNames(
            s.card,
            namespace,
            expanded ? s.expanded : null,
            cardHeight && !hideAdditionalComponents ? s.hoverCard : null,
            editor && s.disableTransitions,
          )}
          data-hook={DH.card}
        >
          <div className={this.getContainerClasses()} ref={this.card} onMouseLeave={this.scrollToTop}>
            <div className={classNames(s.imageContainer, imageRatioClassName)} data-hook="image">
              <div className={classNames(s.imageOverlay, s.evCardImageBackgroundColor)} />
              <div className={s.image} style={this.getImageStyle()} />
            </div>
            <div className={classNames(s.content, s.evCardContentColor)} ref={this.content} data-hook="content">
              <div className={s.cardContentItem} ref={this.topContainer}>
                {showRibbon ? (
                  <div className={s.ribbonContainer} data-hook="ribbon-container">
                    <Ribbon event={event} className={s.ribbon} />
                  </div>
                ) : null}
                <div className={s.title} data-hook="title">
                  {event.title}
                </div>
                <div className={classNames(s.shortDateLocation, s.evCardContentFont)} data-hook="short-date-location">
                  <ShortDateLocation
                    event={event}
                    showDate={showDate}
                    showLocation={showVenue}
                    alignment={alignment}
                    fullLocale={fullLocale}
                  />
                </div>
              </div>
              <div className={classNames(s.cardContentItem, s.detailsContainer)}>
                <div className={classNames(s.details, s.evCardContentFont)} data-hook="details" ref={this.details}>
                  <div className={s.dateLocation}>
                    <FullDateLocation
                      event={event}
                      showDate={showFullDate}
                      showLocation={showLocation}
                      fullLocale={fullLocale}
                    />
                  </div>
                  {event.description ? (
                    <div className={s.description} data-hook="description">
                      {event.description}
                    </div>
                  ) : null}
                  <div className={s.socialBar}>
                    <SocialBar t={t} event={event} />
                  </div>
                </div>
              </div>
            </div>
            <div className={s.bottomContainer} ref={this.bottom}>
              {showMembers ? (
                <div className={s.members} data-hook="members">
                  <Members event={event} />
                </div>
              ) : null}
              <div className={s.buttonSection} data-hook="button-section">
                <RsvpButton event={event} />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
