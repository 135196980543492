import {CalendarDayWithEvents} from '../../../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MobileMonthlyDay as MobileMonthlyDayPresentation} from './mobile-monthly-day'

export interface MobileMonthlyDayOwnProps {
  dayWithEvents: CalendarDayWithEvents
}

export interface MobileMonthlyDayRuntimeProps {
  borderStyleRight: React.CSSProperties
  borderStyleBottom: React.CSSProperties
}

export interface MobileMonthlyDayProps extends MobileMonthlyDayRuntimeProps, MobileMonthlyDayOwnProps {}

const mapRuntime = (context: AppProps): MobileMonthlyDayRuntimeProps => ({
  borderStyleRight: getBorderStyle(context, 'right'),
  borderStyleBottom: getBorderStyle(context, 'bottom'),
})

export const MobileMonthlyDay = connect<MobileMonthlyDayOwnProps, MobileMonthlyDayRuntimeProps>(mapRuntime)(
  MobileMonthlyDayPresentation,
)
