import {withNamespaces} from 'react-i18next'
import {getCalendarMonthWithEvents, getReferenceDate} from '../../../../../selectors/calendar-layout'
import {getBorderStyle} from '../../../../../utils/calendar'
import {AppProps} from '../../../../app/interfaces'
import {connect} from '../../../../runtime-context/connect'
import {MobileMonthlyDaysOwnProps, MobileMonthlyDaysRuntimeProps} from './interfaces'
import {MobileMonthlyDays as MobileMonthlyDaysPresentation} from './mobile-monthly-days'

const mapRuntime = (context: AppProps): MobileMonthlyDaysRuntimeProps => ({
  calendarDays: getCalendarMonthWithEvents(context, getReferenceDate(context.state)),
  borderStyleTop: getBorderStyle(context, 'top'),
  borderStyleBottom: getBorderStyle(context, 'bottom'),
})

export const MobileMonthlyDays = connect<MobileMonthlyDaysOwnProps, MobileMonthlyDaysRuntimeProps>(mapRuntime)(
  withNamespaces()(MobileMonthlyDaysPresentation),
)
export * from './interfaces'
