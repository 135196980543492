import {getFormattedLocation, getFormattedShortStartDate, getFormattedStartDate} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {BrowserOnly} from '../../../commons/components/browser-only'
import {getAlignmentClassName, getListAlignmentClassName} from '../../selectors/classes'
import * as sc from '../classes.scss'
import * as s from './short-date-location.scss'
import {ShortDateLocationProps} from '.'

export const ShortDateLocation = ({event, alignment, showDate, showLocation, fullLocale}: ShortDateLocationProps) => (
  <div
    className={classNames(s.container, sc.textLineHeight, s[getListAlignmentClassName(alignment)], {
      [s.noDate]: !showDate,
      [s.noLocation]: !showLocation,
    })}
    data-hook="ev-short-date-location"
  >
    <div className={s.date} data-hook="short-date">
      <BrowserOnly>{getFormattedShortStartDate(event, fullLocale)}</BrowserOnly>
    </div>
    <div className={s.divider} />
    <div className={s.location} data-hook="short-location">
      {getFormattedLocation(event)}
    </div>
  </div>
)

export const SingleEventShortDateLocation = ({
  event,
  alignment,
  showDate,
  showLocation,
  onImage,
  fullLocale,
  mobile,
}: ShortDateLocationProps) => (
  <div
    className={classNames(
      onImage ? s.onImageCompactTextsFont : s.compactTextsFont,
      s.singleEventContainer,
      sc.textLineHeight,
      s[getAlignmentClassName(alignment)],
      {
        [s.noDate]: !showDate,
        [s.noLocation]: !showLocation,
        [s.mobile]: mobile,
      },
    )}
    data-hook="ev-short-date-location"
  >
    <div className={s.dateWrapper}>
      <div className={s.date} data-hook="short-date">
        <BrowserOnly>{getFormattedStartDate(event, fullLocale)}</BrowserOnly>
      </div>
      <div className={s.divider} />
    </div>
    <div className={s.location} data-hook="short-location">
      {getFormattedLocation(event)}
    </div>
  </div>
)
