import {TranslationFunction} from 'i18next'
import {getCountdownMarginBottom, isCountdownVisible} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {withMargin} from '../../hoc/with-margin'
import {Countdown as CountdownPresentation} from './countdown'

interface CountdownOwnProps {
  event: wix.events.Event
  t: TranslationFunction
  onImage: boolean
  isMobile: boolean
}

export interface CountdownProps extends CountdownOwnProps {}

const getMargin = ({state}: AppProps) => getCountdownMarginBottom(state)

export const Countdown = renderIfVisible(withMargin(CountdownPresentation, getMargin), isCountdownVisible)
