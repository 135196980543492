import * as classNames from 'classnames'
import * as React from 'react'
import {WIDGET_LAYOUT} from '../../../../commons/constants/settings'
import {getLayoutClassName} from '../../../selectors/classes'
import {DH} from '../../../utils/data-hooks'
import {Image as CommonImage} from '../../commons/image'
import * as s from './image.scss'
import {ImageProps} from '.'

export class Image extends React.Component<ImageProps> {
  getContainerStyle = () => {
    const {layout, size, isMobile} = this.props

    if (isMobile) {
      return {
        width: '100%',
        height: layout === WIDGET_LAYOUT.BACKGROUND ? '100%' : `${size}%`,
      }
    }

    switch (layout) {
      case WIDGET_LAYOUT.FULL:
      default:
        return {
          width: `${size}%`,
          height: 'auto',
        }
      case WIDGET_LAYOUT.VERTICAL:
        return {
          width: '100%',
          height: `${size}%`,
        }
      case WIDGET_LAYOUT.BACKGROUND:
        return {
          width: '100%',
          height: '100%',
        }
    }
  }

  render() {
    const {event, layout, gridPosition, opacity, fitImage, isMobile} = this.props

    return (
      <>
        {layout === WIDGET_LAYOUT.BACKGROUND && <div className={s.overlay} data-hook="ev-image-background" />}
        <div
          className={classNames(s[getLayoutClassName(layout)], {[s.mobile]: isMobile})}
          style={this.getContainerStyle()}
          id={'ev-image'}
          data-hook={DH.singleImage}
        >
          <CommonImage
            image={event.mainImage}
            opacity={layout === WIDGET_LAYOUT.BACKGROUND ? opacity : 100}
            gridPosition={gridPosition}
            fitImage={fitImage}
          />
        </div>
      </>
    )
  }
}
