import * as classNames from 'classnames'
import * as React from 'react'
import * as s from './ribbon.scss'
import {RibbonProps} from '.'

export const Ribbon: React.FC<RibbonProps> = ({visible, text, status, t, className, overrideClassNames}: RibbonProps) =>
  visible ? (
    <div className={classNames(className)}>
      <div
        className={classNames(s.root, status ? s.status : s.offer, {
          [overrideClassNames?.offer?.container]: !status,
          [overrideClassNames?.status?.container]: status,
        })}
        data-hook="ribbon"
      >
        <div
          className={classNames(s.text, {
            [overrideClassNames?.offer?.text]: !status,
            [overrideClassNames?.status?.text]: status,
          })}
        >
          {t(text)}
        </div>
      </div>
    </div>
  ) : null
