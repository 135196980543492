import {addQueryParams} from '@wix/wix-events-commons-statics'
import {Multilingual} from '../types/state'

export const getEventUrl = (pageUrl: string = '', event: wix.events.Event, multilingual: Multilingual) => {
  const url = `${pageUrl}/${event.slug}`

  if (multilingual.isEnabled) {
    const lang = multilingual.currentLanguage
    return addQueryParams(url, {lang})
  }

  return url
}
