import * as React from 'react'
import * as classNames from 'classnames'
import * as s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

const getButtonClasses = (style: number, fullWidth: boolean, mobile: boolean) => {
  const classes = [s.button, 'a11yOutline', fullWidth ? s.fullWidth : '']

  if ([2, 4].includes(style)) {
    const radiusClass = style === 4 ? s.evRsvpButtonRoundedBorderRadius : s.evRsvpButtonBorderRadius
    classes.push(s.evRsvpHollowButtonFont, s.evRsvpHollowButtonColor, s.evRsvpButtonBorder, radiusClass)
  }

  if ([1, 3].includes(style)) {
    const radiusClass = style === 3 ? s.evRsvpButtonRoundedBorderRadius : s.evRsvpButtonBorderRadius
    classes.push(s.evRsvpButtonFont, s.evRsvpButtonColor, s.evRsvpButtonBackground, radiusClass)
  }

  return classNames(classes, {[s.mobile]: mobile})
}

export const RsvpButton: React.FC<RsvpButtonProps> = ({
  text,
  style,
  dataHook = 'ev-rsvp-button',
  navigateToPage,
  fullWidth,
  minWidth = 160,
  mobile,
  maxWidth,
  additionalStyle = {},
  withoutRegistration,
}) => {
  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigateToPage()
  }

  return (
    <button
      data-hook={dataHook}
      style={{minWidth, maxWidth, ...additionalStyle, visibility: withoutRegistration ? 'hidden' : 'visible'}}
      className={getButtonClasses(style, fullWidth, mobile)}
      type="button"
      onClick={onClick}
    >
      {text}
    </button>
  )
}
