import {PagesType, SiteSettings} from '../types/state'

export const isDetailsPageEnabled = (siteSettings: SiteSettings) =>
  siteSettings.settings.pagesType === PagesType.DETAILS_PAGE_ENABLED || isTicketedSite(siteSettings)

export const isTicketedSite = (siteSettings: SiteSettings) => siteSettings.ticketed

export const isFirstEventCreated = (siteSettings: SiteSettings) => siteSettings.firstEventCreated

export const isFirstTimeCompleted = (siteSettings: SiteSettings) => siteSettings.settings.firstTimeCompleted

export const isMembersPromoCanceled = (siteSettings: SiteSettings) => siteSettings.settings.membersPromoCanceled
