import {withNamespaces} from 'react-i18next'
import {getRibbonData} from '../../selectors/events'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {RibbonOwnProps, RibbonRuntimeProps} from './interfaces'
import {Ribbon as Presentation} from './ribbon'

const mapRuntime = ({state}: AppProps, {event}: RibbonOwnProps): RibbonRuntimeProps => getRibbonData(state, event)

export const Ribbon = connect<RibbonOwnProps, RibbonRuntimeProps>(mapRuntime)(withNamespaces()(Presentation))
export * from './interfaces'
