import {getDayMonthText} from '@wix/wix-events-commons-statics'
import {withNamespaces} from 'react-i18next'
import {getFullLocale} from '../../../../../../../commons/selectors/environment'
import {
  getListImageOpacity,
  getListImagePosition,
  getListLocationAndDateFormat,
} from '../../../../../../../commons/selectors/settings'
import {getSelectedDayEventsCount} from '../../../../../../selectors/calendar-layout'
import {getEventById} from '../../../../../../selectors/events'
import {
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersVisible,
  isSocialShareVisible,
} from '../../../../../../selectors/list-settings'
import {getComponentConfig, isRibbonVisible} from '../../../../../../selectors/settings'
import {getShareFontSize} from '../../../../../../utils/calendar'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventDetailsOwnProps, MonthlyCalendarEventDetailsRuntimeProps} from './interfaces'
import {MonthlyCalendarEventDetails as MonthlyCalendarEventDetailsPresentation} from './monthly-calendar-event-details'

const mapRuntime = (context: AppProps): MonthlyCalendarEventDetailsRuntimeProps => {
  const {state, actions} = context
  const event = getEventById(state, state.calendarLayout.monthly.selectedEventId)

  const componentConfig = getComponentConfig(state)

  return {
    event,
    dayEventCount: getSelectedDayEventsCount(context),
    dateText: getDayMonthText(new Date(Number(state.calendarLayout.monthly.selectedDate)), getFullLocale(state)),
    showImage: Boolean(event.mainImage) && isImageVisible(state),
    showLocation: isLocationVisible(state),
    imagePosition: getListImagePosition(componentConfig),
    imageOpacity: getListImageOpacity(componentConfig),
    showFullDate: isFullDateVisible(state),
    showDescription: isDescriptionVisible(state),
    showShare: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    locationAndDateFormat: getListLocationAndDateFormat(componentConfig),
    fullLocale: getFullLocale(state),
    shareFontSize: parseInt(getShareFontSize(context), 10),
    showRibbon: isRibbonVisible(state, event),
    closeMonthlyCalendarPopup: () => actions.closeMonthlyCalendarPopup(),
    closeMonthlyCalendarEvent: () => actions.closeMonthlyCalendarEvent(),
  }
}

export const MonthlyCalendarEventDetails = connect<
  MonthlyCalendarEventDetailsOwnProps,
  MonthlyCalendarEventDetailsRuntimeProps
>(mapRuntime)(withNamespaces()(MonthlyCalendarEventDetailsPresentation))
export * from './interfaces'
