import {IWixWindowViewMode} from '@wix/native-components-infra/dist/es/src/types/types'
import * as classNames from 'classnames'
import * as React from 'react'
import {I18nextProvider} from 'react-i18next'
import {ErrorPage} from '../../../commons/components/error-page'
import {RuntimeContext} from '../../../commons/components/runtime-context/runtime-context'
import {Direction} from '../../../commons/constants/html'
import {ssrI18N} from '../../../commons/services/i18n'
import {AppLoaded} from '../app-loaded'
import {Layout} from '../layout'
import {isPreview} from '../../../commons/selectors/environment'
import * as s from './app.scss'
import {AppProps} from './interfaces'

export class App extends React.Component<AppProps> {
  componentDidUpdate(prevProps: AppProps) {
    const {actions, host, state} = this.props
    if (actions && host && state) {
      if (isPreview(state) && host.formFactor !== prevProps.host.formFactor) {
        actions.setFormFactor(host.formFactor)
      }
    }
  }

  render() {
    if (!this.props.state) {
      return <ErrorPage />
    }

    const {
      host: {
        viewMode,
        appLoadBI: {loaded},
      },
      actions: {widgetLoaded},
      isRTL,
      pageInfo: {isHomePage},
      translations,
    } = this.props

    return (
      <RuntimeContext.Provider value={this.props}>
        <I18nextProvider i18n={ssrI18N(translations)}>
          <div
            id="wix-events-widget"
            className={classNames(s.root, {eventsRtl: isRTL})}
            dir={isRTL ? Direction.RTL : Direction.LTR}
          >
            <AppLoaded
              host={this.props.host}
              appLoaded643={loaded}
              widgetLoaded={widgetLoaded}
              viewMode={viewMode as IWixWindowViewMode}
              isHomePage={isHomePage}
            />
            <Layout />
          </div>
        </I18nextProvider>
      </RuntimeContext.Provider>
    )
  }
}
