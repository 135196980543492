import {getDifference, EventStatus} from '@wix/wix-events-commons-statics'
import {AnyAction} from 'redux'
import {INJECT_COMPONENT_DRAFT, UPDATE_COMPONENT_DRAFT} from '../actions/component'
import {LOAD_MEMBERS_FOR_EVENTS} from '../actions/members'

const defaultState: ExtendedEvent[] = []

export const events = (state = defaultState, action: AnyAction): ExtendedEvent[] => {
  switch (action.type) {
    case LOAD_MEMBERS_FOR_EVENTS.SUCCESS:
      const guestLists = action.payload as GuestLists

      return [
        ...state.map(event => ({
          ...event,
          ...(guestLists[event.id] || {}),
        })),
      ]
    case INJECT_COMPONENT_DRAFT:
    case UPDATE_COMPONENT_DRAFT.SUCCESS:
      return sortEvents(action.payload.component.events)
    default:
      return state
  }
}

export const sortEvents = (eventList: wix.events.Event[]) => {
  const upcomingEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.SCHEDULED, EventStatus.STARTED])
  const pastEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.ENDED]).reverse()
  const canceledEvents = getEventsByStatusSortedByDate(eventList, [EventStatus.CANCELED])

  return [].concat(upcomingEvents, pastEvents, canceledEvents)
}

const getEventsByStatusSortedByDate = (
  eventList: wix.events.Event[],
  statuses: wix.events.EventStatus[],
): wix.events.Event[] =>
  eventList.filter((event: wix.events.Event) => statuses.indexOf(event.status) > -1).sort(eventComparator)

export const eventComparator = (first: wix.events.Event, second: wix.events.Event) => {
  if (first.scheduling.config.scheduleTbd) {
    return second.scheduling.config.scheduleTbd ? getDifference(second.modified, first.modified) : 1
  }

  if (second.scheduling.config.scheduleTbd) {
    return -1
  }

  const diff = getDifference(first.scheduling.config.startDate, second.scheduling.config.startDate)

  if (diff === 0) {
    return getDifference(second.modified, first.modified)
  }

  return diff
}
