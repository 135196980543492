import * as React from 'react'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {PopupHeader} from '../header'
import * as s from './monthly-calendar-event-list.scss'
import {MonthlyCalendarEventListProps} from '.'

export class MonthlyCalendarEventList extends React.PureComponent<MonthlyCalendarEventListProps> {
  handleKeyPress = (domEvent, eventId) => {
    if (domEvent.key === 'Enter') {
      this.props.openMonthlyCalendarEvent(eventId)
    }
  }

  render() {
    const {dateText, events, closeMonthlyCalendarPopup, openMonthlyCalendarEvent, t} = this.props

    return (
      <div className={s.container} data-hook={`calendar-event-list`}>
        <PopupHeader eventList text={dateText} onClose={closeMonthlyCalendarPopup} t={t} />
        <div className={s.list}>
          {events.map(event => (
            <div
              className={s.event}
              onClick={() => openMonthlyCalendarEvent(event.id)}
              onKeyPress={domEvent => this.handleKeyPress(domEvent, event.id)}
              tabIndex={0}
              data-hook={`event-list-item-${event.id}`}
            >
              <div className={s.info}>
                <div className={s.time} data-hook={`event-time-${event.id}`}>
                  {event.timeText}
                </div>
                <div className={s.title} data-hook={`event-title-${event.id}`}>
                  {event.title}
                </div>
              </div>
              <div className={s.chevron}>
                <ChevronRight size="1.6em" />
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}
