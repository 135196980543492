import * as classNames from 'classnames'
import * as React from 'react'
import {DH} from '../../../utils/data-hooks'
import * as sc from '../../classes.scss'
import * as s from './rsvp-button.scss'
import {RsvpButtonProps} from '.'

export const RsvpButton = ({
  title,
  text,
  roundedButton,
  hollowButton,
  width,
  height,
  borderRadius,
  onImage,
  roundedBorderRadius,
  navigateToPage,
  isMobile,
  responsiveButton,
  hidden,
}: RsvpButtonProps) => {
  const style = {
    minWidth: responsiveButton ? '100%' : width,
    minHeight: height,
    borderRadius: roundedButton ? roundedBorderRadius : borderRadius,
  }

  const className = hollowButton ? s.hollowButton : s.fullButton
  const onImageClassName = hollowButton ? s.onImageHollowButton : s.onImageFullButton

  const onClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    navigateToPage()
  }

  return (
    <div
      id={DH.rsvpButton}
      className={classNames(s.container, {[s.mobile]: isMobile, [s.responsive]: responsiveButton, [s.hidden]: hidden})}
    >
      {title && (
        <div className={classNames(s.title, {[s.onImageTitle]: onImage})} data-hook="ev-rsvp-button-title">
          {title}
        </div>
      )}
      <button
        style={style}
        data-hook="ev-rsvp-button"
        className={classNames(s.button, sc.textLineHeight, className, {[onImageClassName]: onImage})}
        onClick={onClick}
      >
        {text}
      </button>
    </div>
  )
}
