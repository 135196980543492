import * as classNames from 'classnames'
import * as React from 'react'
import {Resizable} from '../../../commons/components/hoc/resizable'
import {Title} from '../list-title'
import SideBySideItem from './side-by-side-item'
import * as s from './side-by-side.scss'
import {Utils} from './utils'
import {SideBySideProps} from '.'

export class SideBySide extends React.Component<SideBySideProps> {
  utils: Utils

  constructor(props: SideBySideProps) {
    super(props)
    this.utils = new Utils()
  }

  getConfig = () => {
    const {showImage, showMembers, showRibbon, showSocial, showDescription} = this.props
    return {
      showImage,
      showMembers,
      showRibbon,
      showSocial,
      showDescription,
    }
  }

  render() {
    const {events, showImage, margins, width, t} = this.props

    this.utils.resetItems()

    return (
      <Resizable {...this.getConfig()} resizeFunc={this.resize}>
        <div className={s.container}>
          <Title />
          <div
            className={s.items}
            data-hook="side-by-side-items"
            style={{minWidth: this.utils.getMinItemsWidth(showImage)}}
          >
            {events.map((event: ExtendedEvent, index: number) => (
              <div
                key={event.id}
                ref={element => this.utils.addItem(element)}
                className={classNames(s.item, this.utils.itemsHeightClass(width))}
                data-hook="side-by-side-item"
                style={{marginBottom: margins}}
              >
                <SideBySideItem event={event} evenItem={!!(index % 2)} t={t} />
              </div>
            ))}
          </div>
        </div>
      </Resizable>
    )
  }

  resize = () => {
    this.utils.setItemsHeight()

    if (this.props.editor) {
      this.utils.resizeContainer(this.props.showImage)
    }
  }
}
