import {getFormattedStartTime} from '@wix/wix-events-commons-statics'
import {Moment} from 'moment-timezone'
import {getFullLocale} from '../../../../../../../../commons/selectors/environment'
import {openMonthlyCalendarEvent} from '../../../../../../../actions/calendar-layout'
import {AppProps} from '../../../../../../app/interfaces'
import {connect} from '../../../../../../runtime-context/connect'
import {MobileMonthlyEvent as MobileMonthlyEventPresentation} from './mobile-monthly-event'

export interface MobileMonthlyEventOwnProps {
  event: ExtendedEvent
  date: Moment
}

export interface MobileMonthlyEventRuntimeProps {
  selectedEventId: string
  eventTime: string
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
}

export interface MobileMonthlyEventProps extends MobileMonthlyEventRuntimeProps, MobileMonthlyEventOwnProps {}

const mapRuntime = (
  {state, actions}: AppProps,
  ownProps: MobileMonthlyEventOwnProps,
): MobileMonthlyEventRuntimeProps => ({
  selectedEventId: state.calendarLayout.monthly.selectedEventId,
  eventTime: getFormattedStartTime(ownProps.event, getFullLocale(state)),
  openMonthlyCalendarEvent: actions.openMonthlyCalendarEvent,
})

export const MobileMonthlyEvent = connect<MobileMonthlyEventOwnProps, MobileMonthlyEventRuntimeProps>(mapRuntime)(
  MobileMonthlyEventPresentation,
)
