import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../../commons/selectors/environment'
import {getTodayButtonStyle, getTodayButtonText} from '../../../../commons/selectors/settings'
import {getComponentConfig} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {TodayButton as TodayButtonPresentation} from './today-button'

export interface TodayButtonOwnProps {
  onClick: () => void
  dataHook?: string
}

export interface TodayButtonRuntimeProps {
  todayButtonText: string
  todayButtonStyle: number
  isMobile: boolean
}

export interface TodayButtonProps extends TodayButtonRuntimeProps, TodayButtonOwnProps, WithNamespaces {}

const mapRuntime = ({state}: AppProps): TodayButtonRuntimeProps => {
  const componentConfig = getComponentConfig(state)

  return {
    todayButtonText: getTodayButtonText(componentConfig),
    todayButtonStyle: getTodayButtonStyle(componentConfig),
    isMobile: isMobile(state),
  }
}

export const TodayButton = connect<TodayButtonOwnProps, TodayButtonRuntimeProps>(mapRuntime)(
  withNamespaces()(TodayButtonPresentation),
)
