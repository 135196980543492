import * as classNames from 'classnames'
import * as React from 'react'
import {Divider} from '../divider'
import * as s from './date-location.scss'
import {Date, Location} from './presentation'
import {DateLocationProps} from '.'

export const DateLocation = ({
  event,
  dateVisible,
  locationVisible,
  dividerVisible,
  verticalDividerWidth,
  verticalDividerHeight,
  horizontalDividerWidth,
  horizontalDividerHeight,
  horizontal,
  alignmentClassName,
  isMobile,
}: DateLocationProps) => (
  <div
    className={classNames(s[alignmentClassName], {
      [s.noDate]: !dateVisible,
      [s.noLocation]: !locationVisible,
      [s.noDivider]: !dividerVisible,
      [s.horizontal]: horizontal,
      [s.mobile]: isMobile,
    })}
    data-hook="ev-date-location"
  >
    <Date event={event} hocClassName={s.date} dataHook={'date'} />
    {dividerVisible && (
      <div className={s.verticalDivider}>
        <Divider width={verticalDividerWidth} height={verticalDividerHeight} />
      </div>
    )}
    {dividerVisible && (
      <div className={s.horizontalDivider}>
        <Divider width={horizontalDividerWidth} height={horizontalDividerHeight} />
      </div>
    )}
    <Location event={event} dataHook={'location'} hocClassName={s.location} />
  </div>
)
